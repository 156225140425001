// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

import { USE_NATIVE_SCROLL } from './core/config'
import ScrollService from './services/Scroll'

if (USE_NATIVE_SCROLL) {
    ScrollService.useNative()
} else {
    ScrollService.useVirtual()
}

import ModalService from './services/ModalService'

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'

import { loadComponents } from './core/componentUtils'

loadComponents().then(() => {
    const id = window.location.hash.replace('#', '')
    const anchor = document.getElementById(id)
    if (anchor) {

        setTimeout(() => {
            let offset = anchor.matches('.Section:not(.Section--appended):not(.Section--bare)') ? 0 : 80
            offset = anchor.getBoundingClientRect().top + ScrollService.getScroll().y - offset
            ScrollService.setPosition(0, offset)
        }, 100)
    }
})

/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'
